import { getLiffId, getUserID } from '../utils';

export default function initPages() {
  const userId = getUserID();
  const liffId = getLiffId();
  return {
    userId,
    liffId,
  };
}
