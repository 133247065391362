import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getURLSearchParamByKey } from 'src/utils';
import { validatePasscode } from 'src/services/user.service';
import { phoneValidationSuccess } from '../../lib/liff';

const IFrameFullScreen = styled.iframe`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background-color: white;
  z-index: 9999;
`;

const SurveyCake = ({
  id,
  currentLineUser,
  userId,
  displayName,
  lineProfilePhone,
}) => {
  const useLineProfilePhone = lineProfilePhone && !currentLineUser.Phone;
  const handleValidate = () => {
    if (useLineProfilePhone) {
      validatePasscode({
        phone: lineProfilePhone,
        lineVerify: true,
      }).then(() => {
        const trimmedPhone = lineProfilePhone.replace(/\+886/g, '0');
        phoneValidationSuccess(trimmedPhone);
      });
    }
  };

  const handleIframeLoad = () => {
    handleValidate();
  };

  const surveyCakeUrl = getURLSearchParamByKey('open') || '';
  const aka_clientid =
    !surveyCakeUrl.includes('aka_clientid') && id ? `aka_clientid=${id}` : '';

  const aka_phone =
    currentLineUser && currentLineUser.phone
      ? `aka_phone=${currentLineUser.phone}`
      : lineProfilePhone != '' && lineProfilePhone != null
      ? `aka_phone=${lineProfilePhone.replace(/\+886/g, '0')}`
      : '';

  const aka_lineID =
    currentLineUser && currentLineUser.lineID
      ? `aka_lineid=${currentLineUser.lineID}`
      : userId != '' && userId != null
      ? `aka_lineid=${userId}`
      : '';

  const aka_name =
    currentLineUser && currentLineUser.name
      ? `aka_name=${currentLineUser.name}`
      : displayName != '' && displayName != null
      ? `aka_name=${displayName}`
      : '';

  const aka_idnum =
    currentLineUser && currentLineUser.idNum
      ? `aka_idnum=${currentLineUser.idNum}`
      : '';

  const querystrings = [
    aka_clientid,
    aka_phone,
    aka_lineID,
    aka_name,
    aka_idnum,
  ].filter((q) => q !== '');
  let url = surveyCakeUrl;
  if (surveyCakeUrl.includes('?')) {
    url += '&' + querystrings.join('&');
  } else {
    url += '?' + querystrings.join('&');
  }
  return (
    <IFrameFullScreen src={url} onLoad={handleIframeLoad}></IFrameFullScreen>
  );
};

SurveyCake.propTypes = {
  id: PropTypes.string,
  userId: PropTypes.string,
  currentLineUser: PropTypes.shape({
    phone: PropTypes.string,
    lineID: PropTypes.string,
    name: PropTypes.string,
  }),
  displayName: PropTypes.string,
  lineProfilePhone: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default SurveyCake;
