import React, { useEffect, useState } from 'react';
import SurveyCake from 'pages/surveyCake';
import usePages from 'src/customHooks/pages';
import { getUserInfo } from '../../../services/user.service';
import { getURLSearchParamByKey } from 'src/utils';

export default function Home() {
  const { message, token, lineProfilePhone, lineProfile } = usePages();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserInfo()
      .then((user) => {
        if (
          user.patients &&
          user.patients.length !== 0 &&
          user.patients.find((u) => u.isSelf)
        ) {
          const self = user.patients.find((u) => u.isSelf);
          setUser({ ...self, ...user });
        } else {
          setUser(user);
        }
      })
      .catch((err) => {
        console.log('取得使用者資訊失敗', err.message);
        return;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (!loading && token) {
    return (
      <SurveyCake
        id={getURLSearchParamByKey('clientID')}
        userId={message.userId}
        currentLineUser={{
          phone: user && user.Phone !== '' ? user.Phone : undefined,
          lineID: message.userId,
          name: user && user.name !== '' ? user.name : undefined,
          idNum: user && user.idNum !== '' ? user.idNum : undefined,
        }}
        displayName={lineProfile?.displayName}
        lineProfilePhone={lineProfilePhone}
      ></SurveyCake>
    );
  }
  return null;
}
